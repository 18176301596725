<template>
  <section class="tables">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="card-title">Tous les clients </div>


            <nav aria-label="breadcrumb" class="float-right">
              <ol class="breadcrumb">
                <router-link to="addclient">
                  <li class="breadcrumb-item btn btn-primary">
                    Ajouter un client
                  </li>
                </router-link>
              </ol>
            </nav>


            <div class="table-responsive">
              <input
                value=""
                class="form-control search-input"
                placeholder="Recherche par nom"
                type="text"
                v-model="search"
              />
            <table class="table">
              <thead>
                <tr>
                  <th>Nom</th>
                  <th>Catégorie</th>
                  <th>Email</th>
                  <th>Telephone</th>
                  <th>Adresse</th>

                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(user, key) in searchFunction" :key="key">
                  <td>{{ user.name }}</td>
                  <td>{{ user.categorie }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ user.phone }}</td>
                  <td>{{ user.adresse }}</td>
                  <td>
                    <router-link
                      :to="{ name: 'updateclient', params: { id: user._id } }"
                      ><i
                        class="mdi mdi-pen icone text-success"
                        style="color: green"
                      ></i> </router-link
                    >&nbsp;
                    <i
                      class="mdi mdi-delete icone text-danger"
                      style="color: red"
                      @click="deletclient(user._id)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
 import { HTTP } from '@/axios';
import Swal from "sweetalert2";
import Toast from "sweetalert2";
import axios from 'axios';
export default {
  data() {
    return {
      clients: [],
      search:''
    };
  },  

    computed: {
    searchFunction() {
      return this.clients.filter((item) => {
        return (
          item.name
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
  },

  created() {
    this.getclients();
  },
  methods: {
    
    getclients() {
       HTTP.get("clients/getclients")
        .then((response) => {
          this.clients = response.data;
          console.log(this.clients)
        })
        .then((err) => console.log(err));
    },

    deletclient(id) {
      Swal.fire({
        title: "Êtes-vous sûrs ?",
        text: "Vous voulez supprimé le client",
        icon: "warning",
        cancelButtonText: "Annuler",
        showCancelButton: true,
        confirmButtonColor: "#3e884f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, valider!",
      }).then((result) => {
        if (result.isConfirmed) {
          HTTP
            .delete("clients/deleteclient/" + id)
            .then((response) => {
              //this.$htmlToPaper("printNow");

              Toast.fire({
                position: "top-center",
                icon: "success",
                title: "Client supprimé",
              });

              this.getclients();
            });
        }
      });
    },
  },
};
</script>
